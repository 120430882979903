import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminEventUpdateComponent } from './admin-event-update/admin-event-update.component';
import { AdminEventAddPresenceComponent } from './admin-event-add-presence/admin-event-add-presence.component';

@Component({
  selector: 'app-admin-event',
  templateUrl: './admin-event.component.html',
  styleUrls: ['./admin-event.component.css']
})
export class AdminEventComponent implements OnInit {
  eventMin = {
    status:true,
    filter:'',
    max: 10,
    offset: 0,
  }

  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;

  metadata = {
    search: new Rx.Subject<string>(),
    asistencia:true,
    falta:false
  }
  manzanaList = [];


  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
      this.metadata.search.debounceTime(700)
      .switchMap(val => {
          console.log('called once',val)
          if(val == ''){
            this.list = [];
            this.eventMin.filter = '';
          }else{
            this.list = [];
          }
          this.getevents();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
        console.log('error logged:');
        console.log(error);
      });
    }

    ngOnInit(): void {
      this.getevents();
      this.listByManzana();
    }

      /**función para obtener el listado de categorias
      */
      getevents(){
        this.loadding.wait();
        if(this.eventMin.filter == ''){
          this.session.postRequest("event:findAllByStatus",this.eventMin).subscribe((data:any)=>{
            for(let i = 0; i < data.object.instanceList.length; i++){
              data.object.instanceList[i].paginator = {
                total:0,
                pageSizeOptions:[ 2 ,5, 10, 25, 100]
              }
              data.object.instanceList[i].presenceMin = {
                  offset:0,
                  max:10,
                  event:{
                    id:''
                  }
                }
            }
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:event:findAllByStatus",error);
            this.loadding.hide();
          })
        }else{
          this.session.postRequest("event:searching",this.eventMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:event:list",error);
            this.loadding.hide();
          })
        }

      }

      /** funcionalidad para crear una categoria
      */
      update(uuid){
        const dialogRef = this.dialog.open(AdminEventUpdateComponent, {
          width: '36rem',
          data: uuid,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.getevents();
            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }

      /**funcionalidad para cargar asistencias por manzana
      */
      loadAsistencesByMAnzana(object){
        const dialogRef = this.dialog.open(AdminEventAddPresenceComponent, {
          width: '36rem',
          data: object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.snackBar.open('Asistencias actualizadas correctamente', '', {
                duration: 1000
              });
            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }

      delete(id){
        this.loadding.wait();
        this.session.postRequest("event:delete",{id:id}).subscribe((data:any)=>{
          for(let i=0; i < this.list.length; i++){
            if(this.list[i].id == id){
              this.list.splice(i,1);
            }
          }
          this.snackBar.open('Registro eliminado', '', {
            duration: 1000
          });
          this.loadding.hide();
        },error=>{
          console.log(error);
          this.loadding.hide();
        })
      }

      getAsistence(object){
        console.log(":::::::::::",object);
        object.presenceList = [];
        object.presenceMin.event.id = object.id;
        this.loadding.show(true, "Espere un momento...");
        this.session.postRequest('presence:findAllByEvent',object.presenceMin).subscribe((data:any)=>{
          object.presenceList = data.object.instanceList;
          object.paginator.total = data.object.total;
          // cargamos los total de asistencias por manzanas
          object.manzanaList = this.manzanaList.map((item)=>{
            return {
              totalAsistence:0,
              totalInasistence:0,
              manzana:item.manzana
            };
          });
          for(let i = 0; i < object.manzanaList.length; i++){
            this.getAsistencesByEventAndManzana(object,object.manzanaList[i]);
          }
          this.loadding.hide();
        },
        (error)=>{
          console.log('Error:presence:findAllByEvent',error)
          this.loadding.hide();
        });
        this.getTotal(object);
      }




      updatePresence(object){
        this.loadding.show(true, "Espere un momento...");
        this.session.postRequest('presence:update',object).subscribe((data:any)=>{
          this.loadding.hide();
        },
        (error)=>{
          console.log('Error:presence:findAllByEvent',error)
          this.loadding.hide();
        });
      }


      getTotal(object){
        this.session.postRequest('presence:totalByEvent',{event:{id:object.id}, isHere:true}).subscribe((data:any)=>{
          object.totalAsistence = data.object;
        },
        (error)=>{
          console.log('Error:presence:totalByEvent',error)
        });
        this.session.postRequest('presence:totalByEvent',{event:{id:object.id}, isHere:false}).subscribe((data:any)=>{
          object.totalINAsistence = data.object;
        },
        (error)=>{
          console.log('Error:presence:totalByEvent',error)
        });
      }

      // funcion para obtener los datos del paginado.
      onPaginateChange(event){
        this.eventMin.max = event.pageSize;
        this.eventMin.offset = (event.pageIndex * event.pageSize);
        this.getevents();
      }

      onPaginateChangePresense(event,object){
        object.presenceMin.max = event.pageSize;
        object.presenceMin.offset = (event.pageIndex * event.pageSize);
        this.getAsistence(object);
      }


      /**funcionalidad para obtener el listado de manzanas
      */
      listByManzana(){
        this.session.postRequest("citizen:getAllByManzanaNumber",{}).subscribe((data:any)=>{
          let manzanaList = data.object.instanceList.sort(function(a, b) {
            return a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
          this.manzanaList = manzanaList.map((item)=>{
            return {
              totalAsistence:0,
              totalInasistence:0,
              manzana:item
            };
          });
          console.log(this.manzanaList);
        },error=>{
          console.log(error);
        })
      }


      /**funcionalidad para obtener las asistencias e inacistencias de un evento por manzana
      **/
      getAsistencesByEventAndManzana(event,manzana){
        this.session.postRequest("presence:totalByEventAndIsHereAndManzanaNumberAndStatus",{
          event:{
            id:event.id
          },
          citizen:{
            status:'Ciudadano'
          },
          isHere:true,
          manzanaNumber:manzana.manzana
        }).subscribe((data:any)=>{
          manzana.totalAsistence = data.object
        },error=>{
          console.log(error);
        })
        this.session.postRequest("presence:totalByEventAndIsHereAndManzanaNumberAndStatus",{
          event:{
            id:event.id
          },
          citizen:{
            status:'Ciudadano'
          },
          isHere:false,
          manzanaNumber:manzana.manzana
        }).subscribe((data:any)=>{
          manzana.totalInasistence = data.object
        },error=>{
          console.log(error);
        })
      }

}
