<div class="card fondo">

  <div class="container rounded">
    <div class="text-center m-2">
      <div class="row g-0">
        <div class="col-md-2">
          <img class="logo" src="/assets/images/primera-seccion.png" style="width: 6rem;" alt="padron">
        </div>
        <div class="col-md-8">
          <div class="card-body" style="padding: 33px;">
            <h1 class="MontseRegular">Padrón Ciudadano de</h1>
            <div class="color">
              <h1 class="MontseSemiBold">San Pablo Güilá (sección primera)</h1>
            </div>
          </div>
        </div>
      </div>
    </div>


    <mat-tab-group mat-align-tabs="end" class="m-0" >
      <mat-tab>
        <ng-template mat-tab-label>
          <!--<img src="/assets/images/icono-datos-GRIS-05.svg" style="width: 2rem;" alt="">&nbsp;
          Información-->
                <img src="/assets/images/icono-datos-GRIS-05.svg" style="width: 2rem;" alt="">&nbsp;
                Información
              </ng-template>

        <div class="container rounded">
          <div class="row">
            <div class="col-md-2" style="padding-top: 40px;">
              <img src="/assets/images/foto.jpg" style="width: 7rem;" alt="">
            </div>
            <div class="col rounded-end">

                <p class="text-center titulos">DATOS PERSONALES</p><hr>

                <form action="#">

                  <div class="form-row mb-2">
                    <div class="form-group col-md-4">
                        <label for="text" class="form-label contenido">Nombre(s)</label>
                        <input type="text" class="form-control form-control-sm" name="nombre">
                      </div>
                      <div class="form-group col-md-4">
                        <label for="text" class="form-label contenido">Apellido Paterno:</label>
                        <input type="text" class="form-control form-control-sm" name="apellidoPaterno">
                      </div>
                      <div class="form-group col-md-4">
                        <label for="text" class="form-label contenido">Apellido Materno:</label>
                        <input type="text" class="form-control form-control-sm" name="apellidoMaterno">
                      </div>
                    </div>

                    <div class="form-row mb-2">
                      <div class="form-group col-md-4">
                        <label for="email" class="form-label contenido">Número de Manzana</label>
                        <input type="number" class="form-control form-control-sm" name="NumManzana">
                      </div>
                      <div class="form-group col-md-4">
                          <label for="text" class="form-label contenido">CURP:</label>
                          <input type="text" class="form-control form-control-sm" name="curp">
                        </div>
                        <div class="form-group col-md-4">
                          <label for="text" class="form-label contenido">Fecha de Nacimiento:</label>
                          <input type="date" class="form-control form-control-sm" name="fechaNacimiento">
                        </div>
                      </div>

                      <p class="text-center" style="padding-top: 0px;">DATOS GENERALES</p>

                      <div class="form-row mb-2">
                        <div class="form-group col-md-4">
                          <label for="email" class="form-label contenido">Número de Integrantes:</label>
                          <input type="number" class="form-control form-control-sm" name="integrantes">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="text" class="form-label contenido">Domicilio:</label>
                            <input type="text" class="form-control form-control-sm" name="domicilio">
                          </div>
                          <div class="form-group col-md-4">
                            <label for="text" class="form-label contenido">Colonia:</label>
                            <input type="text" class="form-control form-control-sm" name="colonia">
                          </div>
                        </div>

                        <p class="text-center titulos">SERVICIOS A LA COMUNIDAD</p><hr>

                        <div class="form-row mb-2">
                          <div class="form-group col-md-4">
                            <label for="email" class="form-label contenido">Servicio:</label>
                            <input type="text" class="form-control form-control-sm" name="servicio">
                          </div>
                          <div class="form-group col-md-4">
                              <label for="text" class="form-label contenido">Fecha de inicio:</label>
                              <input type="date" class="form-control form-control-sm" name="fechaInicio">
                            </div>
                            <div class="form-group col-md-4">
                              <label for="text" class="form-label contenido">Fecha de entrega:</label>
                              <input type="date" class="form-control form-control-sm" name="fechaEntrega">
                            </div>
                          </div>

                          <div class="form-row mb-2">
                            <div class="form-group col-md-4">
                              <label for="email" class="form-label contenido">Jefe de Manzana:</label>
                              <input type="text" class="form-control form-control-sm" name="jefeManzana">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="text" class="form-label contenido">Año:</label>
                                <input type="number" class="form-control form-control-sm" name="año">
                              </div>
                              <div class="form-group form-check">
                                <p class="text-center contenido">¿Quién acude?</p>

                                  <label class="checkbox-inline contenido">
                                    <input type="checkbox">&nbsp;Titular
                                  </label> &nbsp;
                                  <label class="checkbox-inline contenido">
                                    <input type="checkbox">&nbsp;Suplente
                                  </label>

                              </div>
                            </div>
                </form>
              <div class="d-grid gap-2 col-6 mx-auto">
                <button class="btn btnColor" type="button">Registrar</button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab >

        <ng-template mat-tab-label naranja>

          <img src="/assets/images/icono-historial-GRIS-06.svg" style="width: 2rem;" alt=""> &nbsp;
          Historial

        </ng-template>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button style="padding: 2px;" class="accordion-button  contenido" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Servicios
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col" class="colorTable MontseSemiBold">Servicio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="MonseConTeble">Tequio anual</td>
                    </tr>
                    <tr>
                      <td class="MonseConTeble">Tequio anual</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo" >
              <button  style="padding: 2px;" class="accordion-button collapsed  contenido" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Asambleas
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <table class="table">
                  <thead>
                    <tr class="colorTable MontseSemiBold">
                      <th scope="col">Concepto</th>
                      <th scope="col">Fecha <br> asist/inasist</th>
                      <th scope="col">Multa</th>
                      <th scope="col">Estatus</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="MonseConTeble">Ordinaria</td>
                    </tr>
                    <tr>
                      <td class="MonseConTeble">Ordinaria</td>
                    </tr>
                    <tr>
                      <td class="MonseConTeble">Ordinaria</td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button style="padding: 2px;" class="accordion-button collapsed contenido" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Cooperaciones
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <table class="table table-sm">
                  <thead>
                    <tr class="colorTable MontseSemiBold">
                      <th scope="col">Concepto</th>
                      <th scope="col">Estatus</th>
                      <th scope="col">Fecha de pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="MonseConTeble">
                      <td>Vigilancia</td>
                      <td>PAGADO</td>
                      <td>17/02/2021</td>
                    </tr>
                    <tr class="MonseConTeble">
                      <td>limpieza vial</td>
                      <td>PENDIENTE</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
