<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center spablo-theme" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-12 col-md-3">
        <mat-form-field appearance="fill" style="width:100%;">
          <mat-label>Años</mat-label>
          <select matNativeControl name="year" [(ngModel)]="metadata.year" (change)="findAllByYear(metadata.year);" required>
            <option value="{{year}}" *ngFor="let year of metadata.years">{{year}}</option>
          </select>
        </mat-form-field>
      </div>
    </div>

    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTab($event);">
      <mat-tab label="27 años cumplidos">
        <table class="table table-general">
          <thead class="header-table">
            <tr>
              <th>Nombre</th>
              <th>Manzana</th>
              <th>Domicilio</th>
              <th>Colonia</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let object of list; let i = index;">
              <td>{{object.name + ' ' + object.first_lastname + ' ' + object.second_lastname}}</td>
              <td>{{object.manzana_number}}</td>
              <td>{{object.address}}</td>
              <td>
                {{object.colony}}
              </td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab label="Último servicio (10 años)">
        <table *ngIf="metadata.tab == 1" class="table table-general">
          <thead class="header-table">
            <tr>
              <th>Nombre</th>
              <th>Ultimo servicio</th>
              <th>servicio</th>
              <th>Manzana</th>
              <th>Domicilio</th>
              <th>Colonia</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let object of list; let i = index;">
              <td>{{object.name + ' ' + object.first_lastname + ' ' + object.second_lastname}}</td>
              <td>{{object.citizenService.start_date}} - {{object.citizenService.end_date}}</td>
              <td>{{object.citizenService.service}}</td>
              <td>{{object.manzana_number}}</td>
              <td>{{object.address}}</td>
              <td>
                {{object.colony}}
              </td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab label="Jubilación (60 años)">
        <table class="table table-general">
          <thead class="header-table">
            <tr>
              <th>Nombre</th>
              <th>Fecha de nacimiento</th>
              <th>Manzana</th>
              <th>Domicilio</th>
              <th>Colonia</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let object of list; let i = index;">
              <td>{{object.name + ' ' + object.first_lastname + ' ' + object.second_lastname}}</td>
              <td>{{object.date_of_birth | date:'YYYY'}}</td>
              <td>{{object.manzana_number}}</td>
              <td>{{object.address}}</td>
              <td>
                {{object.colony}}
              </td>
              <td>
                <button mat-icon-button class="accent" style="font-size: smaller;" matTooltip="Editar" routerLink="/admin/citizen/update/{{object.id}}">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
