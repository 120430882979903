import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AdminTreasuryBoardScanComponent } from './admin-treasury-board-scan/admin-treasury-board-scan.component';
import { AdminTreasuryVerifyComponent } from './admin-treasury-verify/admin-treasury-verify.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import * as printJS from 'print-js';

@Component({
  selector: 'app-admin-treasury-board',
  templateUrl: './admin-treasury-board.component.html',
  styleUrls: ['./admin-treasury-board.component.css']
})
export class AdminTreasuryBoardComponent implements OnInit {
  citizenModel = {
    id:'',
    code:'',
    name:'',
    first_lastname:'',
    second_lastname:'',
    manzana_number:'',
    curp:'',
    date_of_birth:'',
    members:'',
    address:'',
    colony:'',
    image:'',
    status:'Ciudadano',
    phone:'',
    email:''
  }
  citizenMin = {
    manzanaNumber:0,
    status:'Ciudadano',
    filter:{
      value:'',
      name:'searchElement'
    },
    max: 10,
    offset: 0,
  }
  metadata = {
    image:'',
    searchCitizen: new Rx.Subject<string>(),
    citizen:JSON.parse(JSON.stringify(this.citizenModel)),
    historialCitizen:[],
    lastMovements:[],
  }
  citizenList = [];

  incidencePaymentModel = {
    id:'',
    amount:'',
    paymentDate:'',
    paymentType:'',//cuota, multa
    object:'',//asamblea (event) ,
    objectUuid:'',
    status:'',//Vigente, Cancelado
    citizen:{
      id:'',
    }
  }
  incidencePaymentList = [];
  incidencePaymentStockList = [];
  incidencePaymentMin = {
    filter:{
      value:'',
      name:''
    },
    max: 10,
    offset: 0,
  }

  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  incidencePaymentCitizenMin = {
    citizen:{
      id:''
    },
    filter:{
      value:'',
      name:''
    },
    max: 5,
    offset: 0,
  }

  paginatorCitizen = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;
  object = JSON.parse(JSON.stringify(this.incidencePaymentModel));
  constructor(private session: SessionService,
    public loadding:LoadingService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
      this.metadata.searchCitizen.debounceTime(700)
      .switchMap(val => {
          console.log('called once',val);
          this.citizenMin.manzanaNumber = 0;
          if(val ===''){
            this.citizenList = [];
            this.citizenMin.filter.name = '';
          }else{
            this.citizenMin.filter.name="searchElement";
            this.citizenList = [];
          }
          this.getCitizens();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
        console.log('error logged:');
        console.log(error);
      });
    }

  ngOnInit(): void {
    this.loadConcept();
    this.getIncidentPayment();
  }

  /**funcionalidad para obtener un listado de 5 conceptos de pago.
  */
  loadConcept(){
    this.incidencePaymentList = [];
    for(let i = 0; i < 1; i ++){
      let auxP=JSON.parse(JSON.stringify(this.incidencePaymentModel));
      this.incidencePaymentList.push(auxP);
    }
  }

  /**funcionalidad para
  */
  cleanConept(index){
    this.incidencePaymentList[index] =  JSON.parse(JSON.stringify(this.incidencePaymentModel));
  }


  getCitizens(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("citizen:findAllByStatus",this.citizenMin).subscribe((data:any)=>{
      for(let  i = 0; i < data.object.instanceList.length; i++ ){
        data.object.instanceList[i].age = this.getAge(data.object.instanceList[i].date_of_birth);
      }
      this.citizenList = data.object.instanceList;
      this.loadding.hide();
    },error=>{
      console.log("error:citizen:findAllByStatus",error);
      this.loadding.hide();
    })
  }

  searchCitizen(){
    if(this.citizenMin.filter.value.length > 3){
      this.metadata.searchCitizen.next(this.citizenMin.filter.value);
    }else{
      this.citizenList = [];
    }
  }

  citizenSelected(ev){
    console.log(ev);
    for(let item of this.citizenList){
      if(item.name + ' ' + item.first_lastname + ' ' + item.second_lastname == ev.option.value){
        console.log("Item",item);
        this.metadata.citizen = JSON.parse(JSON.stringify(item));
         this.metadata.citizen.age = this.getAge(this.metadata.citizen.date_of_birth);
         this.downloadImg(this.metadata.citizen);
        this.citizenHistorial();
      }
    }
  }

  downloadImg(object){
    let aux = {
      id:'',
      name:'',
      file:'',
      type:''
    }
    aux.id = object.id;
    this.session.postRequest("citizen:download",aux).subscribe((data:any)=>{
      // this.loading.hide();
      if(data.object){
        if(data.object.file){
          this.metadata.image = data.object.file.replace(/\-/g,"+");
        }else{
          this.metadata.image = '';
        }
      }else{
        this.metadata.image = '';
      }
    },error=>{
      console.log(error);
      this.loadding.hide();
    });
  }


  /**función para obtener el listado de ultimos movimeintos de un ciudadanos
  **/
  citizenHistorial(){
    this.loadding.show(true,"Espere un momento...");
    this.incidencePaymentCitizenMin.citizen.id = this.metadata.citizen.id;
    this.session.postRequestAnonimus("incidencePayment:findAllByCitizen",this.incidencePaymentCitizenMin).subscribe(async(data:any)=>{
      for(let  i = 0; i < data.object.instanceList.length; i++){
        console.log("SLKJASDLKJsda",data.object.instanceList[i])

        if(data.object.instanceList[i].objectUuid != ''){
          console.log("EL bueno",data.object.instanceList[i])

          await this.getPrecence(data.object.instanceList[i]);
        }
      }
      this.metadata.historialCitizen = data.object.instanceList;
      this.paginatorCitizen.total = data.object.total;
      this.loadding.hide();
    },error=>{
      console.log(error);
      this.loadding.hide();
    })
  }

  getPrecence(object){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("presence:get",{id:object.objectUuid}).subscribe((data:any)=>{
        object.presence = data.object;
        resolve(true);
      },error=>{
        reject(error);
      })
    })
  }

  getAge(age){
    let dB = moment(age);
    let today = moment();
    return today.diff(dB, 'years')
  }

  /**funcionalidad para limpiar los datos e iniciar de nuevo.
  */
  clean(){
    this.incidencePaymentList = [];
    this.citizenList = [];
    this.citizenMin.filter.value = '';
    this.metadata.historialCitizen = [];
    this.metadata.citizen = JSON.parse(JSON.stringify(this.citizenModel));
    this.object = JSON.parse(JSON.stringify(this.incidencePaymentModel));
    this.loadConcept();
    this.getIncidentPayment();
  }

  cleanWithCitizen(){
    this.incidencePaymentList = [];
    this.object = JSON.parse(JSON.stringify(this.incidencePaymentModel));
    this.citizenHistorial();
    this.loadConcept();
    this.getIncidentPayment();
  }

  /**funcionalidad para limpiar los datos e iniciar de nuevo.
  */
  async sendToPay(){
    console.log(this.incidencePaymentList);
    if(this.metadata.citizen.id != ''){
      let incidencePaymentListToSave = [];
      for(let i = 0; i < this.incidencePaymentList.length; i++){
        if(this.incidencePaymentList[i].amount != 0 && this.incidencePaymentList[i].paymentType != ''){
          incidencePaymentListToSave.push(this.incidencePaymentList[i]);
        }
      }

      //comenzamos a salvar los pagos.
      this.loadding.show(true,"Espere un momento");
      console.log(incidencePaymentListToSave);
      for(let item of incidencePaymentListToSave){
        item.paymentDate = moment().format('YYYY-MM-DD');
        item.status = "Vigente";
        item.citizen.id = this.metadata.citizen.id;
        try{
          await this.savePayment(item);

        }catch(e){
          console.log(e);
          this.loadding.hide();
        }
      }
      this.snackBar.open('Gracias el pago fue realizado exitosamente.', 'Aceptar', {duration: 10000});

      this.cleanWithCitizen();
    }else{
      this.snackBar.open('Para poder cobrar se debe terner un ciudadano seleccionado.', 'Aceptar', {duration: 5000});
    }


  }


  savePayment(object){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("incidencePayment:update",object).subscribe(async(data:any)=>{
        object.id = data.object.id;
        this.loadding.hide();
        await this.printTicket(object)
        resolve(true);
      },error=>{
        reject(error);
      });
    });
  }


  /**funcionalidad para obtener el listado de pagos recientes
  **/
  getIncidentPayment(){
    this.session.postRequest("incidencePayment:list",this.incidencePaymentMin).subscribe((data:any)=>{
      this.incidencePaymentStockList = data.object.instanceList;
      this.paginator.total = data.object.total;
    },error=>{
      console.log(error);
    })
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.incidencePaymentMin.max = event.pageSize;
    this.incidencePaymentMin.offset = (event.pageIndex * event.pageSize);
    this.getIncidentPayment();
  }

  onPaginateChangeCitizen(event){
    this.incidencePaymentCitizenMin.max = event.pageSize;
    this.incidencePaymentCitizenMin.offset = (event.pageIndex * event.pageSize);
    this.citizenHistorial();
  }


  /** funcionalidad oara abrir la camara y scaner el QR
  */
  getCitizenWithQR(){
    const dialogRef = this.dialog.open(AdminTreasuryBoardScanComponent, {
      width: '36rem',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          // console.log(result.object)
          let citizenUuid = result.object.substring(result.object.indexOf("|")+1,result.object.lastIndexOf("|"));
          this.loadding.show(true,"Espere un momento");
          this.session.postRequest('citizen:get',{id:citizenUuid}).subscribe((data:any)=>{
            this.metadata.citizen = JSON.parse(JSON.stringify(data.object));
            this.metadata.citizen.age = this.getAge(this.metadata.citizen.date_of_birth);
            this.citizenMin.filter.value = this.metadata.citizen.name + ' ' + this.metadata.citizen.first_lastname + ' ' + this.metadata.citizen.second_lastname;
            this.downloadImg(this.metadata.citizen);
            this.citizenHistorial();
          },
          (error)=>{
            console.log('Error:client:get',error)
            this.loadding.hide();
          });
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**función para imprimir una venta
  */
  printTicket(object){
    return new Promise((resolve,reject)=>{
      this.loadding.show(true,"Obteniendo ticket, espere un momento...");
      this.session.postRequest("reportIncidencePayment:ticket",object).subscribe((data:any)=>{
        this.loadding.hide();
        printJS({printable: data.object, type: 'pdf', base64: true})
        resolve(true);
      },error=>{
        console.log(error);
        this.snackBar.open('No pudo imprimir el ticket', '', {
          duration: 1000
        });
        reject(error);
        this.loadding.hide();
      })
    })

  }


  /** funcionalidad para verificar si un usuario tiene adeudos
  */
  verify(){
    const dialogRef = this.dialog.open(AdminTreasuryVerifyComponent, {
      width: '36rem',
      height: '90%',
      data:this.metadata.citizen
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          console.log(result.object)
          for(let i = 0 ; i < this.incidencePaymentList.length; i++){
            if(i==0){
              this.incidencePaymentList[i].object="Presence";
              this.incidencePaymentList[i].objectUuid=result.object.id;
              this.incidencePaymentList[i].paymentType="Multa";
              this.incidencePaymentList[i].status="Vigente";
              this.incidencePaymentList[i].amount = "200";
            }
          }
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }


}
