<div class="row justify-content-center">
  <div class="col-11">
    <p class="tTitle">Selecciona un concepto y procede al pago.</p>
    <mat-list role="list" style="width:100%;">
      <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="citizen"  [(ngModel)]="metadata.presenceSelected.id">
        <mat-radio-button class="example-radio-button" *ngFor="let object of presenceList"  [value]="object.id" style="    white-space: normal !important;">
          {{object.event.name }} - {{object.event.date | date:'dd-MM-yyyy'}}
        </mat-radio-button>
      </mat-radio-group>
    </mat-list>
  </div>

  <div class="col-11" *ngIf="presenceList.length == 0" class="textAdeudo">
    <h3>EL ciudadano no tiene adeudos.</h3>
  </div>

</div>
<div class="row justify-content-between">
  <div class="col-12 col-md-6 mb-2">
    <button mat-flat-button color="primary" style="width:100%;" (click)="close(false);">CANCELAR</button>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <button mat-flat-button color="accent" style="width:100%;" [disabled]="this.metadata.presenceSelected.id == ''" (click)="close(true);">SELECCIONAR</button>
  </div>
</div>
