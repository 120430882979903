import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-citizen-update',
  templateUrl: './admin-citizen-update.component.html',
  styleUrls: ['./admin-citizen-update.component.css']
})
export class AdminCitizenUpdateComponent implements OnInit {
  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;

  complexForm: FormGroup;
  citizenModel = {
    id:'',
    code:'',
    name:'',
    first_lastname:'',
    second_lastname:'',
    manzana_number:'',
    curp:'',
    date_of_birth:'',
    members:'',
    address:'',
    colony:'',
    image:'',
    status:'Ciudadano',
    phone:'',
    email:''
  }

  citizenServiceModel = {
    id:'',
    service:'',
    start_date:'',
    end_date:'',
    manzana_boss:'',
    year:'',
    entitle:true,
    substitute:false,
    citizen:{
      id:''
    }
  };

  object = JSON.parse(JSON.stringify(this.citizenModel));

  metadata = {
    dateBirth:'',
    startDate:'',
    endDate:'',
    service:JSON.parse(JSON.stringify(this.citizenServiceModel)),
    img:{
      id:'',
      file: '',
      type: '',
      name: '',
      fileName:''
    },
    uploadted:false
  }
  citizenServiceList = [];
  assembliesList = [];
  benefactionList = [];
  statusList = [
    'Ciudadano',
    'Autoridad',
    'Finado',
    'Jubilado',
    'Discapacitado'
  ]

  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public snackBar: MatSnackBar, public loading:LoadingService, public fb: FormBuilder, public location:Location ) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'first_lastname': [null, Validators.compose([Validators.required])],
      'second_lastname': [null, Validators.compose([Validators.required])],
      'manzana_number': [null, Validators.compose([Validators.required])],
      'curp': [null, Validators.compose([Validators.pattern('[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[0-9]{2}')])],
      'date_of_birth': [null, Validators.compose([Validators.required])],
      'address': [null, Validators.compose([Validators.required])],
      'colony': [null, Validators.compose([Validators.required])],
    });
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['id'] == 'new'){
        // no hacemos nada por el momento
      }else{
        this.object.id = params['id'];
        // cargamos los datos de la BD
        this.loading.wait();
        this.session.postRequest('citizen:get',this.object).subscribe((data:any)=>{
          this.loading.hide();
          this.object = JSON.parse(JSON.stringify(data.object));
          this.object.image = '';
          // this.object.status = true;
          this.metadata.dateBirth = moment(this.object.date_of_birth).format('YYYY-MM-DD');
          this.downloadImg();
        },
        (error)=>{
          console.log('Error:client:get',error)
          this.loading.hide();
        });
      }
    });
  }

  send(object,status) {
    return new Promise((resolve,reject)=>{
      this.loading.show(true,'Guardando...');
      // console.log(this.metadata.dateBirth);
      this.object.date_of_birth = moment(this.metadata.dateBirth).format('YYYY-MM-DD');
      if(this.object.id == ''){
        this.object.code = this.guid();
      }
      this.session.postRequest("citizen:update", object).subscribe(
        (data:any) => {
          object.id = data.object.id;
          this.snackBar.open('El registro se guardo correctamente', '', {
            duration: 8000
          });
          this.loading.hide();
          if(status){
            this.generateQR();
            this.back();
            // this.router.navigate(['/admin/citizen']);
          }
          resolve(true);
        },
        error => {
          console.log("Error: citizen:update",error);
          this.loading.hide();
          reject(error);
        }
      );
    });


  }

  cleanServiceSecction(){
    this.metadata.service = JSON.parse(JSON.stringify(this.citizenServiceModel));
    this.metadata.startDate = '';
    this.metadata.endDate = '';
  }

  /**funcionalidad para guardar un servicio
  */
  async sendService(){
    this.loading.show(true,"Guardando espere un momento...");
    if(this.object.id == ''){
      await this.send(this.object,false);
      let aux = JSON.parse(JSON.stringify(this.citizenServiceModel));
      aux = JSON.parse(JSON.stringify(this.metadata.service));
      aux.citizen.id = this.object.id;
      aux.start_date = moment(this.metadata.startDate).format('YYYY-MM-DD');
      aux.end_date = moment(this.metadata.endDate).format('YYYY-MM-DD');
      this.session.postRequest("citizen:addService",aux).subscribe((data:any)=>{
        aux.id = data.object.id;
        this.citizenServiceList.push(aux);
        this.cleanServiceSecction();
        this.snackBar.open('El servicio de guardo, puedes verlo en la pestaña de historial.', '', {
          duration: 10000
        });
        this.loading.hide();
      },error=>{
        console.log("error:citizen:addService",error);
        this.loading.hide();
      });
    }else{
      let aux = JSON.parse(JSON.stringify(this.citizenServiceModel));
      aux = JSON.parse(JSON.stringify(this.metadata.service));
      aux.citizen.id = this.object.id;
      aux.start_date = moment(this.metadata.startDate).format('YYYY-MM-DD');
      aux.end_date = moment(this.metadata.endDate).format('YYYY-MM-DD');
      this.session.postRequest("citizen:addService",aux).subscribe((data:any)=>{
        aux.id = data.object.id;
        this.citizenServiceList.push(aux);
        this.cleanServiceSecction();
        this.snackBar.open('El servicio de guardo, puedes verlo en la pestaña de historial.', '', {
          duration: 10000
        });
        this.loading.hide();
      },error=>{
        console.log("error:citizen:addService",error);
        this.loading.hide();
      });
    }
  }

  getServiceList(){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("citizen:findAllServices",{citizen:{id:this.object.id}}).subscribe((data:any)=>{
      this.citizenServiceList = data.object.instanceList;
      let x = 0;
      for(let i = 0; i < this.citizenServiceList.length; i++){
        x++;
        this.citizenServiceList[i].startDate = moment(this.citizenServiceList[i].start_date).format('YYYY');
        this.citizenServiceList[i].endDate = moment(this.citizenServiceList[i].end_date).format('YYYY');
      }

      while (x < 7) {
        console.log(x);
        let aux = JSON.parse(JSON.stringify(this.citizenServiceModel));
        this.citizenServiceList.push(aux);
        x++;
      }

      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    })
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + s4();
  }


  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     let reader = new FileReader();
     reader.readAsDataURL(file);
     reader.onload = (event:any) => {
       if(this.metadata.img.type == 'image/png' || this.metadata.img.type == 'image/jpeg'){
         this.metadata.img.file = event.target.result;
         this.uploadImg();
       }else{
         this.snackBar.open('El archivo no es un imagen, intenta con otro medio.', '', {
           duration: 10000
         });
       }
     };
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    console.log(this.metadata.img);
    if(this.complexForm.valid){
      await this.send(this.object,false);
      if(this.object.id != ''){
        this.loading.show(true,"Espere un momento...");
        this.metadata.img.id = this.object.id;
        let aux = JSON.parse(JSON.stringify(this.metadata.img));
        aux.file = aux.file.replace(/\+/g,"-");
        aux.filename = aux.name;
        // .replace(/\-/g,"+");
        this.session.postRequest("citizen:upload",aux).subscribe((data:any)=>{
          this.loading.hide();
              this.metadata.uploadted = false;
              this.metadata.img.fileName = data.object.filename;
              this.metadata.img.name = '';
              this.metadata.img.type = '';
        },error=>{
          console.log(error);
          this.loading.hide();
        });
      }else{
        this.metadata.img.file = '';
        this.metadata.img.name = '';
        this.metadata.img.type = '';
        this.snackBar.open('Por favor, guardar la información primero..', '', {
          duration: 10000
        });
      }
    }else{
      this.snackBar.open('Por favor, Ingrese la información del ciudadano primero...', '', {
        duration: 10000
      });
    }



    // try{
    //   this.loading.show(true,"Espere un momento...")
    //   const formData = new FormData();
    //   formData.append('file', this.metadata.img.file, this.metadata.img.name);
    //   this.session.uploadFile("citizen:upload",formData).subscribe(async(data:any)=>{
    //     this.object.image = "http://guila.keysoft.mx/public/citizen/"+data.object.name;
    //     this.metadata.uploadted = false;
    //     this.metadata.img.file = '';
    //     this.metadata.img.name = '';
    //     this.metadata.img.type = '';
    //     this.send(this.object,false);
    //     this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
    //     this.loading.hide();
    //   },error=>{
    //     console.log("citizen:upload",error);
    //     this.loading.hide();
    //   })
    // }catch(e){
    //   console.log(e);
    //   this.loading.hide();
    // }

  }

/**función para descargar imagen
*/

downloadImg(){
  let aux = {
    id:'',
    name:'',
    file:'',
    type:''
  }
  aux.id = this.object.id;
  this.session.postRequest("citizen:download",aux).subscribe((data:any)=>{
    // this.loading.hide();
    if(data.object){
      if(data.object.file){
        this.metadata.img.fileName = data.object.filename;
        this.metadata.img.file = data.object.file.replace(/\-/g,"+");
        this.metadata.img.name = '';
        this.metadata.img.type = '';
      }
    }
  },error=>{
    console.log(error);
    this.loading.hide();
  });
}
  /**funcionalidad para descargar QR
  */
  generateQR(){
    this.session.postRequest("citizen:generateQR",this.object).subscribe((data:any)=>{
    },error=>{
    })
  }

  deleteService(object){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("citizen:deleteService",object).subscribe((data:any)=>{
      for(let i = 0; i < this.citizenServiceList.length; i++){
        if(this.citizenServiceList[i].id == object.id){
          this.citizenServiceList.splice(i,1);
          break;
        }
      }
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    })
  }


  /**función para guardar un servicio.
  */
  saveService(object){
    object.citizen.id = this.object.id;
    object.start_date = object.startDate+'-01-01';
    object.end_date = object.endDate+'-01-01';
    object.year = object.startDate;
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("citizen:addService",object).subscribe((data:any)=>{
      object.id = data.object.id;
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }


    changeTab(ev){
      // console.log(ev);
      if(ev.index == 1){
        this.getServiceList();
      }
    }

    back(){
      this.location.back();
    }
}
