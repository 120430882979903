
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code: "api:login",
    endpoint: "api/login"
  }, {
    code: "session:create",
    endpoint: "validate/validate"
  },
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: 'auth:signup',
    endpoint: 'auth/signup'
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "citizen:get",
    endpoint: "citizen/get"
  },{
    code: "citizen:update",
    endpoint: "citizen/update"
  },{
    code: "citizen:findAllByStatus",
    endpoint: "citizen/findAllByStatus"
  },{
    code: "citizen:delete",
    endpoint: "citizen/delete"
  },{
    code: "citizen:upload",
    endpoint: "citizen/upload"
  },{
    code: "citizen:addService",
    endpoint: "citizen/addService"
  },{
    code: "citizen:deleteService",
    endpoint: "citizen/deleteService"
  },{
    code: "citizen:findAllServices",
    endpoint: "citizen/findAllServices"
  },{
    code: "citizen:generateQR",
    endpoint: "citizen/generateQR"
  },{
    code: "citizen:downloadCard",
    endpoint: "citizen/downloadCard"
  },{
    code: "citizen:download",
    endpoint: "citizen/download"
  },{
    code: "citizen:findAllByStatusAndBetweenDateOfBirth",
    endpoint: "citizen/findAllByStatusAndBetweenDateOfBirth"
  },{
    code: "citizen:findAllByStatusAndRetiredDateOfBirth",
    endpoint: "citizen/findAllByStatusAndRetiredDateOfBirth"
  },{
    code: "citizen:findAllByCitizenServEndDateAndStatus",
    endpoint: "citizen/findAllByCitizenServEndDateAndStatus"
  },{
    code: "event:update",
    endpoint: "event/update"
  },{
    code: "event:get",
    endpoint: "event/get"
  },{
    code: "event:delete",
    endpoint: "event/delete"
  },{
    code: "event:findAllByStatus",
    endpoint: "event/list"
  },{
    code: "benefaction:update",
    endpoint: "benefaction/update"
  },{
    code: "benefaction:get",
    endpoint: "benefaction/get"
  },{
    code: "benefaction:delete",
    endpoint: "benefaction/delete"
  },{
    code: "benefaction:findAllByStatus",
    endpoint: "benefaction/findAllByStatus"
  },{
    code: "presence:update",
    endpoint: "presence/update"
  },{
    code: "presence:findByCitizen",
    endpoint: "presence/findByCitizen"
  },{
    code: "presence:findAllByEvent",
    endpoint: "presence/findAllByEvent"
  },{
    code: "presence:totalByEvent",
    endpoint: "presence/totalByEvent"
  },{
    code: "citizen:getAllByManzanaNumber",
    endpoint: "citizen/getAllByManzanaNumber"
  },{
    code: "citizen:findAllByManzanaNumber",
    endpoint: "citizen/findAllByManzanaNumber"
  },{
    code: "citizen:findAllByStatusAndManzanaNumber",
    endpoint: "citizen/findAllByStatusAndManzanaNumber"
  },{
    code: "incidencePayment:findAllByCitizen",
    endpoint: "incidencePayment/findAllByCitizen"
  },{
    code: "incidencePayment:update",
    endpoint: "incidencePayment/update"
  },{
    code: "incidencePayment:get",
    endpoint: "incidencePayment/get"
  },{
    code: "incidencePayment:findAllByStatus",
    endpoint: "incidencePayment/findAllByStatus"
  },{
    code: "incidencePayment:findAllByObject",
    endpoint: "incidencePayment/findAllByObject"
  },{
    code: "incidencePayment:list",
    endpoint: "incidencePayment/list"
  },{
    code: "reportIncidencePayment:ticket",
    endpoint: "reportIncidencePayment/ticket"
  },{
    code: "presence:findAllByCitizenAndIsHere",
    endpoint: "presence/findAllByCitizenAndIsHere"
  },{
    code: "presence:get",
    endpoint: "presence/get"
  },{
    code: "event:close",
    endpoint: "event/close"
  },{
    code: "presence:totalByEventAndIsHereAndManzanaNumberAndStatus",
    endpoint: "presence/totalByEventAndIsHereAndManzanaNumberAndStatus"
  }
];

export default urls;
