import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  metadata = {
    years: [],
    year: moment().isoWeekYear(),
    search: new Rx.Subject<string>(),
    tab: 0,
  }

  citizenMin = {
    status: 'Ciudadano',
    date: '',
    filter: {
      value: '',
      name: ''
    },
    max: 10,
    offset: 0,
  }

  list = [];
  constructor(private session: SessionService, public loadingService: LoadingService, public dialog: MatDialog, public snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    // Obtenemos todos las personas que cumplen 27 años de acuerdo a la fecha corriente
    // this.findAllByBetweenDateOfBirth(this.metadata.startDate);

    let currentYear = moment().isoWeekYear();
    this.metadata.years.push((currentYear));
    for (var i = 1; i < 5; i++) {
      this.metadata.years.push((currentYear - i));
    }

    // Obtenemos las personas que cumplen 27 años
    if (this.metadata.tab == 0) {
      this.findAllByStatusAndBetweenDateOfBirth(this.metadata.year).then((data:any) => {
        this.list = data.object.instanceList;
      });
    }
  }

  findAllByStatusAndBetweenDateOfBirth(year) {
    return new Promise((resolve,reject) => {
      // Obtenemos el día y mes corriente y sustituimos el año por el seleccionado
      this.loadingService.show(true, "Obteniendo los ciudadanos, espere un momento...");
      this.citizenMin.date = moment().format('DD') + '/' + moment().format('MM') + '/' + year;
      this.session.postRequest("citizen:findAllByStatusAndBetweenDateOfBirth", this.citizenMin).subscribe(
        (data: any) => {
          resolve({transaction: 'ok', object: { instanceList: data.object.instanceList, total: data.object.total } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: citizen:findAllByStatusAndBetweenDateOfBirth", error);
          this.loadingService.hide();
        });
      });
  }

  findAllByStatusAndRetiredDateOfBirth(year) {
    return new Promise((resolve,reject) => {
      // Obtenemos el día y mes corriente y sustituimos el año por el seleccionado
      this.loadingService.show(true, "Obteniendo los ciudadanos, espere un momento...");
      this.citizenMin.date = moment().format('DD') + '/' + moment().format('MM') + '/' + year;
      this.session.postRequest("citizen:findAllByStatusAndRetiredDateOfBirth", this.citizenMin).subscribe(
        (data: any) => {
          resolve({transaction: 'ok', object: { instanceList: data.object.instanceList, total: data.object.total } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: citizen:findAllByStatusAndRetiredDateOfBirth", error);
          this.loadingService.hide();
        });
      });
  }

  findAllByYear(year) {
    this.metadata.year = year;
    switch(this.metadata.tab){
      case 0:{
        this.list = [];
        this.findAllByStatusAndBetweenDateOfBirth(this.metadata.year).then((data:any) => {
          this.list = data.object.instanceList;
        });
        break;
      }
      case 1:{
        this.list = [];
        this.findAllByCitizenServEndDateAndStatus(this.metadata.year).then((data:any) => {
          for (let x=0; x<data.object.instanceList.length; x++) {
            data.object.instanceList[x].citizenService.end_date = moment(data.object.instanceList[x].citizenService.end_date).format('YYYY')
            data.object.instanceList[x].citizenService.start_date = moment(data.object.instanceList[x].citizenService.start_date).format('YYYY')
            this.list.push(data.object.instanceList[x])
          }
        });
        break;
      }
      case 2:{
        this.list = [];
        this.findAllByStatusAndRetiredDateOfBirth(this.metadata.year).then((data:any) => {
          this.list = data.object.instanceList;
        });
        break;
      }
    }
  }

  findAllByCitizenServEndDateAndStatus(year) {
    return new Promise((resolve,reject) => {
      // Obtenemos el día y mes corriente y sustituimos el año por el seleccionado
      this.loadingService.show(true, "Obteniendo los ciudadanos, espere un momento...");
      this.citizenMin.date = moment().format('DD') + '/' + moment().format('MM') + '/' + year;
      this.session.postRequest("citizen:findAllByCitizenServEndDateAndStatus", this.citizenMin).subscribe(
        (data: any) => {
          resolve({transaction: 'ok', object: { instanceList: data.object.instanceList, total: data.object.total } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: citizen:findAllByCitizenServEndDateAndStatus", error);
          this.loadingService.hide();
        });
      });
  }

  changeTab(ev){
    switch(ev.index){
      case 0:{
        this.metadata.tab = 0;
        this.list = [];
        this.findAllByStatusAndBetweenDateOfBirth(this.metadata.year).then((data:any) => {
          this.list = data.object.instanceList;
        });
        break;
      }
      case 1:{
        this.metadata.tab = 1;
        this.list = [];
        this.findAllByCitizenServEndDateAndStatus(this.metadata.year).then((data:any) => {
          for (let x=0; x<data.object.instanceList.length; x++) {
            data.object.instanceList[x].citizenService.end_date = moment(data.object.instanceList[x].citizenService.end_date).format('YYYY')
            data.object.instanceList[x].citizenService.start_date = moment(data.object.instanceList[x].citizenService.start_date).format('YYYY')
            this.list.push(data.object.instanceList[x])
          }
        });
        break;
      }
      case 2:{
        this.metadata.tab = 2;
        this.list = [];
        this.findAllByStatusAndRetiredDateOfBirth(this.metadata.year).then((data:any) => {
          this.list = data.object.instanceList;
        });
        break;
      }
    }
  }
}
