<div class="row justify-content-center">
  <div class="col-11">
    <p>Cargue asistencias por manzana.</p>
    <br>
    <mat-form-field appearance="fill" >
      <mat-label>Manzana</mat-label>
      <mat-select name="appleName" [(ngModel)]="metadata.apple">
        <mat-option *ngFor="let object of appleList" [value]="object.value">
          {{object.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="row justify-content-between">
      <div class="col-5">
        <button mat-flat-button color="primary" style="width:100%;" (click)="close(false);">Cerrar</button>

      </div>
      <div class="col-5">
        <button mat-flat-button color="accent" style="width:100%;" (click)="send();">Agregar asistencias</button>

      </div>
    </div>
  </div>
</div>
