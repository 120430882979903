
<div class="row justify-content-center">
  <div class="col-11">
    <h3>Nueva Asamblea</h3>
  </div>
</div>
<div class="row justify-content-center spablo-theme">
  <div class="col-11">
    <div class="row">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>

      <div class="form-row mb-2">
        <div class="form-group col-md-12">
          <label for="email" class="form-label contenido">Nombre*:</label>
          <input type="text" class="form-control form-control-sm" name="NumManzana" [ngClass] = "complexForm.controls['name'].invalid && complexForm.controls['name'].touched ? 'is-invalid' : complexForm.controls['name'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
          <div class="invalid-feedback" *ngIf="complexForm.controls['name'].hasError('required')  && complexForm.controls['name'].touched">Campo requerido</div>
        </div>
        <div class="form-group col-md-4">
          <label for="text" class="form-label contenido">Fecha*:</label>
          <input type="date" class="form-control form-control-sm" name="fecha" [ngClass] = "complexForm.controls['date'].invalid && complexForm.controls['date'].touched ? 'is-invalid' : complexForm.controls['date'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['date']" [(ngModel)]="metadata.date" required>
          <div class="invalid-feedback" *ngIf="complexForm.controls['date'].hasError('required')  && complexForm.controls['date'].touched">Campo requerido</div>
        </div>
        <div class="form-group col-md-3">
          <label for="text" class="form-label contenido">Hora(24hrs)*:</label>
          <input type="number" class="form-control form-control-sm" name="hr" [ngClass] = "complexForm.controls['hr'].invalid && complexForm.controls['hr'].touched ? 'is-invalid' : complexForm.controls['hr'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['hr']" [(ngModel)]="object.hr" required>
          <div class="invalid-feedback" *ngIf="complexForm.controls['hr'].hasError('required')  && complexForm.controls['hr'].touched">Campo requerido</div>
        </div>
        <div class="form-group col-md-3">
          <label for="text" class="form-label contenido">Minuto*:</label>
          <input type="number" class="form-control form-control-sm" name="min" [ngClass] = "complexForm.controls['min'].invalid && complexForm.controls['min'].touched ? 'is-invalid' : complexForm.controls['min'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['min']" [(ngModel)]="object.min" required>
          <div class="invalid-feedback" *ngIf="complexForm.controls['min'].hasError('required')  && complexForm.controls['min'].touched">Campo requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label for="text" class="form-label contenido">Descripción:</label>
          <textarea name="name" rows="8" cols="80"class="form-control form-control-sm" name="fecha" [(ngModel)]="object.description" required></textarea>
        </div>


      </div>

    </div>
    <div class="row justify-content-end mt-3">
      <div class="col-8">
        <button mat-flat-button color="primary" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
