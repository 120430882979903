import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

import * as moment from 'moment';

@Component({
  selector: 'app-admin-event-update',
  templateUrl: './admin-event-update.component.html',
  styleUrls: ['./admin-event-update.component.css']
})
export class AdminEventUpdateComponent implements OnInit {

  complexForm: FormGroup;

  eventModel = {
    id:'',
    name:'',
    description:'',
    date:'',
    hr:'',
    min:'',
    status:true
  };
  metadata = {
    date:'',
  }

  object = JSON.parse(JSON.stringify(this.eventModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminEventUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'date': [null, Validators.compose([Validators.required])],
      'hr': [null, Validators.compose([Validators.required])],
      'min': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
      // cargamos los datos de la BD
      this.session.postRequest('event:get',{id:this.data}).subscribe((data:any)=>{
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.date = moment(this.object.date).format('YYYY-MM-DD');
        this.loadingService.hide();
      },
      (error)=>{
        this.loadingService.hide();
        console.log('Error: event:get',error)
      })
    }
  }

  /**
  * funcion para guardar una unidades de medida
  */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.object.date = moment(this.metadata.date).format('YYYY-MM-DD');

    this.session.postRequest("event:update",object).subscribe(
      (data:any) => {
        this.snackBar.open('El fabricante se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: event:update",error);
        this.loadingService.hide();
      }
    );
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'event:001';
    }
    this.dialogRef.close(object);
  }

}
