import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminBenefactionUpdateComponent } from './admin-benefaction-update/admin-benefaction-update.component';

@Component({
  selector: 'app-admin-benefaction',
  templateUrl: './admin-benefaction.component.html',
  styleUrls: ['./admin-benefaction.component.css']
})
export class AdminBenefactionComponent implements OnInit {

  benefactionMin = {
    status:true,
    filter:'',
    max: 10,
    page: 1,
  }

  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;

  metadata = {
    search: new Rx.Subject<string>(),
  }
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
      this.metadata.search.debounceTime(700)
      .switchMap(val => {
          console.log('called once',val)
          if(val == ''){
            this.list = [];
            this.benefactionMin.filter = '';
          }else{
            this.list = [];
          }
          this.getBenefactions();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
        console.log('error logged:');
        console.log(error);
      });
    }

    ngOnInit(): void {
      this.getBenefactions();
    }

      /**función para obtener el listado de categorias
      */
      getBenefactions(){
        this.loadding.wait();
        if(this.benefactionMin.filter == ''){
          this.session.postRequest("benefaction:findAllByStatus",this.benefactionMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:benefaction:findAllByStatus",error);
            this.loadding.hide();
          })
        }else{
          this.session.postRequest("benefaction:searching",this.benefactionMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:benefaction:list",error);
            this.loadding.hide();
          })
        }

      }

      /** funcionalidad para crear una categoria
      */
      update(uuid){
        const dialogRef = this.dialog.open(AdminBenefactionUpdateComponent, {
          width: '36rem',
          data: uuid,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.getBenefactions();
            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }

      delete(id){
        this.loadding.wait();
        this.session.postRequest("benefaction:delete",{id:id}).subscribe((data:any)=>{
          for(let i=0; i < this.list.length; i++){
            if(this.list[i].id == id){
              this.list.splice(i,1);
            }
          }
          this.snackBar.open('Registro eliminado', '', {
            duration: 1000
          });
          this.loadding.hide();
        },error=>{
          console.log(error);
          this.loadding.hide();
        })
      }

      // funcion para obtener los datos del paginado.
      onPaginateChange(benefaction){
        this.benefactionMin.max = benefaction.pageSize;
        this.benefactionMin.page = benefaction.pageIndex + 1;
        this.getBenefactions();
      }

}
