import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PadronComponent } from './padron/padron.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';

import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminToolbarComponent } from './admin/admin-toolbar/admin-toolbar.component';
import { AdminCitizenComponent } from './admin/admin-citizen/admin-citizen.component';
import { AdminCitizenUpdateComponent } from './admin/admin-citizen/admin-citizen-update/admin-citizen-update.component';
import { AdminEventComponent } from './admin/admin-event/admin-event.component';
import { AdminBenefactionComponent } from './admin/admin-benefaction/admin-benefaction.component';
import { AdminEventUpdateComponent } from './admin/admin-event/admin-event-update/admin-event-update.component';
import { AdminBenefactionUpdateComponent } from './admin/admin-benefaction/admin-benefaction-update/admin-benefaction-update.component';
import { AdminTreasuryBoardComponent } from './admin/admin-treasury-board/admin-treasury-board.component';
import { AdminCitizenRetiredComponent } from './admin/admin-citizen/admin-citizen-retired/admin-citizen-retired.component';
import { AdminCitizenHandicappedComponent } from './admin/admin-citizen/admin-citizen-handicapped/admin-citizen-handicapped.component';
import { AdminTreasuryBoardScanComponent } from './admin/admin-treasury-board/admin-treasury-board-scan/admin-treasury-board-scan.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminTreasuryVerifyComponent } from './admin/admin-treasury-board/admin-treasury-verify/admin-treasury-verify.component';
import { AdminEventAddPresenceComponent } from './admin/admin-event/admin-event-add-presence/admin-event-add-presence.component';
import { DialogOverviewExampleDialog } from './admin/admin-citizen/admin-citizen.component';

@NgModule({
  declarations: [
    AppComponent,
    PadronComponent,
    AdminLoginComponent,
    AdminToolbarComponent,
    AdminCitizenComponent,
    AdminCitizenUpdateComponent,
    AdminEventComponent,
    AdminBenefactionComponent,
    AdminEventUpdateComponent,
    AdminBenefactionUpdateComponent,
    AdminTreasuryBoardComponent,
    AdminCitizenRetiredComponent,
    AdminCitizenHandicappedComponent,
    AdminTreasuryBoardScanComponent,
    AdminDashboardComponent,
    AdminTreasuryVerifyComponent,
    AdminEventAddPresenceComponent,
    DialogOverviewExampleDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ZXingScannerModule,
    MatTabsModule,
    MatGridListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgbModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents:[
    AdminEventUpdateComponent,
    AdminBenefactionUpdateComponent,
    AdminTreasuryBoardScanComponent,
    AdminTreasuryVerifyComponent,
    AdminEventAddPresenceComponent,
    DialogOverviewExampleDialog,
  ]
})
export class AppModule { }
