import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';

import { PadronComponent } from './padron/padron.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminCitizenComponent } from './admin/admin-citizen/admin-citizen.component';
import { AdminCitizenUpdateComponent } from './admin/admin-citizen/admin-citizen-update/admin-citizen-update.component';
import { AdminEventComponent } from './admin/admin-event/admin-event.component';
import { AdminBenefactionComponent } from './admin/admin-benefaction/admin-benefaction.component';
import { AdminTreasuryBoardComponent } from './admin/admin-treasury-board/admin-treasury-board.component';
import { AdminCitizenRetiredComponent } from './admin/admin-citizen/admin-citizen-retired/admin-citizen-retired.component';
import { AdminCitizenHandicappedComponent } from './admin/admin-citizen/admin-citizen-handicapped/admin-citizen-handicapped.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';

const routes: Routes = [
  { path :'' ,redirectTo:'admin/login' , pathMatch:'full' },
  { path:'admin/login',component: AdminLoginComponent  },
  { path:'admin/citizen',component: AdminCitizenComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/citizen/update/:id',component: AdminCitizenUpdateComponent , canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/padron',component: PadronComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/event',component: AdminEventComponent , canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/benefaction',component: AdminBenefactionComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/treasury/board',component: AdminTreasuryBoardComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/citizen/retired',component: AdminCitizenRetiredComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/citizen/handicapped',component: AdminCitizenHandicappedComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}},
  { path:'admin/dashboard',component: AdminDashboardComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
