<app-admin-toolbar></app-admin-toolbar>
  <div class="row justify-content-center spablo-theme" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">

        </div>
        <div class="col-3 text-right">
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="tCardBox">
            <div class="row pl-2">
              <div class="col-12">
                <p class="tTitle">Tesoreria</p>
              </div>
              <div class="col-10">
                <mat-form-field style="width:100%;" appearance="fill">
                  <input matInput placeholder="Buscar ciudadano"  autocomplete="off" name="searchCitizend" [(ngModel)]="citizenMin.filter.value" [matAutocomplete]="auto" (ngModelChange)="searchCitizen()" >
                  <mat-icon matSuffix>search</mat-icon>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="citizenSelected($event);">
                    <mat-option *ngFor="let object of citizenList" [value]="object.name + ' ' + object.first_lastname + ' ' + object.second_lastname">
                      {{object.name + ' ' + object.first_lastname + ' ' + object.second_lastname}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-2">
                <button mat-icon-button color="primary" matTooltip="Buscar por código QR." (click)="getCitizenWithQR();"> <span class="material-icons">qr_code_scanner</span> </button>
              </div>

              <div class="col-12 mb-2">
                <p class="tileText">Comprueba si se tiene una deuda pendiente.</p>
                <button mat-flat-button color="primary" (click)="verify();" [disabled]="metadata.citizen.id  == ''">Verificar</button>
                <br>
              </div>
              <div class="col-12 mb-2">
                <p class="tileText">Agregar un concepto de cobro.</p>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th scope="col" class="colorTable MontseSemiBold">Tipo</th>
                      <th scope="col" class="colorTable MontseSemiBold">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let object of incidencePaymentList; let i = index;">
                      <td><strong>#{{i +1}}</strong></td>
                      <td class="MonseConTeble">
                        <mat-form-field appearance="legacy">
                          <select matNativeControl required name="type{{i}}" [(ngModel)]="object.paymentType">
                            <option value="Multa">Multa</option>
                            <option value="Cuota">Cuota</option>
                          </select>
                        </mat-form-field>
                      </td>
                      <td class="MonseConTeble">
                        <div class="form-group">
                          <input type="number" class="form-control form-control-sm" name="amount{{i}}" [(ngModel)]="object.amount">
                        </div>
                      </td>
                      <td>
                        <button mat-icon-button matTooltip="Quitar" color="primary" (click)="cleanConept(i);"> <mat-icon>delete</mat-icon></button>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>



            </div>
            <div class="row justify-content-end">
              <div class="col-12 col-md-4 mb-2 mr-2">
                <button mat-flat-button color="primary" style="width:100%;" (click)="clean();">NUEVO</button>
              </div>
              <div class="col-12 col-md-4 mb-2 mr-2">
                <button mat-flat-button color="accent" style="width:100%;" [disabled]="this.metadata.citizen.id == ''" (click)="sendToPay();">COBRAR</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 ">
          <div class="tCardBox">
            <div class="row">
              <div class="col-12">
                <p class="tTitle">Datos</p>
                <div class="mainItem p-2" *ngIf="this.metadata.citizen.id != ''">
                  <div class="leftItem">
                    <p class="tileText">{{ this.metadata.citizen.name + ' ' + this.metadata.citizen.first_lastname + ' ' + this.metadata.citizen.second_lastname  }}</p>
                    <p class="tileText">{{this.metadata.citizen.age}} Años</p>
                    <p class="tileText">Manzana: {{this.metadata.citizen.manzana_number}}</p>
                    <p class="tileText">Domicilio: {{ this.metadata.citizen.address }}</p>
                  </div>
                  <div class="rightItem">
                    <div class="boxIMg">
                      <img src="/assets/images/foto.jpg" style="width: 100%;" alt="" *ngIf="metadata.image == ''">
                      <img src="{{metadata.image}}" style="width: 100%;" alt="" *ngIf="metadata.image != ''">
                    </div>
                  </div>
                </div>
                <div class="mainItem p-2" *ngIf="this.metadata.citizen.id == ''">
                  <div class="leftItem">
                    <p class="tileText">No hay un ciudadano seleccionado.</p>
                  </div>
                </div>

              </div>
              <div class="col-12">
                <p class="tTitle">Historial</p>
                <ul style="list-style-type:none; padding: 10px;">
                  <li class="mainItem" *ngFor="let object of metadata.historialCitizen">
                    <div class="leftItem">
                      <p class="mainText">{{object.paymentType}}</p>
                      <span class="minText" *ngIf="object.objectUuid != ''">{{object.presence.event.name}}</span>
                    </div>
                    <div class="rightItem">
                      <p class="mainText">{{object.amount | currency:'CAD':'symbol-narrow'}}</p>
                      <span class="minText">{{ object.paymentDate | date:'dd-MM-yyyy' }}</span>
                      <button mat-icon-button color="primary" matTooltip="Imprimir" (click)="printTicket(object);"> <span class="material-icons">print</span> </button>
                    </div>
                  </li>
                  <li class="mainItem" *ngIf="metadata.historialCitizen.length == 0">
                    <div class="leftItem">
                      <p class="tileText">No hay movimientos.</p>
                    </div>
                  </li>
                </ul>
                <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                  <div class="col-12 ">
                    <mat-paginator [length]=paginatorCitizen.total [pageSize]="5" [pageSizeOptions]=paginatorCitizen.pageSizeOptions (page)="onPaginateChangeCitizen($event)" style="background: #f4f4f5;">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="tCardBox">
            <div class="row">
              <div class="col-12">
                <p class="tTitle">Ultimos Movimientos</p>
                <ul style="list-style-type:none; padding: 10px;">
                  <li class="mainItem" *ngFor="let object of incidencePaymentStockList">
                    <div class="leftItem">
                      <p class="mainText">{{object.citizen.name + ' ' +  object.citizen.first_lastname + ' ' + object.citizen.second_lastname}}</p>
                      <span class="minText">{{object.paymentType}}</span>
                    </div>
                    <div class="rightItem">
                      <p class="mainText">{{object.amount | currency:'CAD':'symbol-narrow'}}</p>
                      <span class="minText">{{ object.paymentDate | date:'dd-MM-yyyy' }}</span>
                      <button mat-icon-button color="primary" matTooltip="Imprimir" (click)="printTicket(object);"> <span class="material-icons">print</span> </button>
                    </div>
                  </li>
                  <li class="mainItem" *ngIf="incidencePaymentStockList.length == 0">
                    <div class="leftItem">
                      <p class="tileText">No hay movimientos.</p>
                    </div>
                  </li>
                </ul>
                <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
                  <div class="col-6 ">
                    <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
