import { environment } from '../../../environments/environment';
/**url / endpoint para la conexión al backend
* *Si environment.production es true devuelve url de "url_de_producción"
* *Si environment.production es false devuelve url de "url_de_desarrollo"
*
*/
// const server_url:string = environment.production ? 'http://25.17.175.137:8171/SPG-PAC-0421-Backend/':'http://25.17.175.137:8171/SPG-PAC-0421-Backend/';
const server_url:string = environment.production ? 'https://guila.keysoft.mx/SPG-PAC/':'https://guila.keysoft.mx/SPG-PAC/';

export default server_url;
