<div class="row p-2 m-0" [ngClass]="{'menuFixed' : metadata.menuFixed}" #menu style="height: 12vh; background-image: url('/../../../assets/images/fondo.png');
  background-repeat: no-repeat;
  background-position: left center;">
  <div class="col-2 logo" style="height: 100%;" routerLink="/admin/dashboard">
    <img src="assets/images/primera-seccion.png" style="height: 100%; padding: 5px;">
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;" *ngIf="metadata.role == 'ROLE_ADMINISTRATION'">
    <ul class="font-platino menu" data-animation="center">
      <li class="horizontal"><a routerLink="/admin/dashboard" [ngClass]="metadata.currentUrl == '/admin/dashboard' ? 'aActive':''">Indicadores</a></li>
      <li class="horizontal"><a routerLink="/admin/treasury/board" [ngClass]="metadata.currentUrl == '/admin/treasury/board' ? 'aActive':''">Tesoreria</a></li>
      <li class="horizontal"><a routerLink="/admin/event" [ngClass]="metadata.currentUrl == '/admin/event' ? 'aActive':''">Asambleas</a></li>
      <li class="horizontal"><a routerLink="/admin/citizen" [ngClass]="metadata.currentUrl == '/admin/citizen' ? 'aActive':''">Ciudadanos</a></li>
      <!-- <li class="horizontal"><a  [matMenuTriggerFor]="menuCatalog" style="cursor:pointer;">Cátalogos</a></li> -->

      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;" *ngIf="metadata.role == 'ROLE_TAX_AUTHORITIES'">
    <ul class="font-platino menu" data-animation="center">
      <li class="horizontal"><a routerLink="/admin/citizen" [ngClass]="metadata.currentUrl == '/admin/citizen' ? 'aActive':''">Ciudadanos</a></li>
      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;" *ngIf="metadata.role == 'ROLE_TREASURY'">
    <ul class="font-platino menu" data-animation="center">
      <li class="horizontal"><a routerLink="/admin/treasury/board" [ngClass]="metadata.currentUrl == '/admin/treasury/board' ? 'aActive':''">Tesoreria</a></li>
      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
</div>

<mat-menu #menuCatalog="matMenu">
  <button mat-menu-item routerLink="/admin/benefaction">Tipos de Cooperaciones</button>
</mat-menu>
