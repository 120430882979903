import { Component, OnInit, Inject } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-treasury-board-scan',
  templateUrl: './admin-treasury-board-scan.component.html',
  styleUrls: ['./admin-treasury-board-scan.component.css']
})
export class AdminTreasuryBoardScanComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  allowedFormats = [
     BarcodeFormat.QR_CODE, BarcodeFormat.EAN_13, BarcodeFormat.CODE_128, BarcodeFormat.DATA_MATRIX
  ]

  constructor(public dialogRef: MatDialogRef<AdminTreasuryBoardScanComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  scanError(ev){
    // console.log(ev);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.close(true);
    // console.log(this.qrResultString);
  }

  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  camerasNotFound(ev){
    console.log(ev);
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.qrResultString;
    }else{
      object.transaction = 'bad';
      object.code = 'benefaction:001';
    }
    this.dialogRef.close(object);
  }

}
