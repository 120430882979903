<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center spablo-theme" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-end">
      <div class="col-6" style="text-align: right;">
        <button mat-button color="accent" routerLink="/admin/citizen">General</button>
        <button mat-button color="primary" routerLink="/admin/citizen/retired">Finados</button>
        <button mat-button color="primary" routerLink="/admin/citizen/handicapped">Discapacidad</button>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Ciudadanos</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" routerLink="/admin/citizen/update/new">
          <mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nuevo Ciudadanos
        </button>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-12 col-md-3">
        <mat-form-field appearance="fill" style="width:100%;">
          <mat-label>Manzana</mat-label>
          <select matNativeControl name="mMananza" [(ngModel)]="citizenMin.manzanaNumber" (change)="getcitizens();" required>
            <option value="0">TODOS</option>
            <option value="{{object}}" *ngFor="let object of manzanaList">{{object}}</option>
          </select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3">
        <mat-form-field appearance="fill" style="width:100%;">
          <mat-label>Etiqueta</mat-label>
          <select matNativeControl required name="omStatus" [(ngModel)]="citizenMin.status" (change)="getcitizens();">
            <option value="{{object}}" *ngFor="let object of statusList">{{object == 'Ciudadano' ? 'Activo' : object}}</option>
          </select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <mat-form-field style="width:100%;" appearance="fill">
          <input matInput placeholder="Buscar" [(ngModel)]="citizenMin.filter.value" autocomplete="off" (ngModelChange)="search()">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Nombre</th>
          <th>Edad</th>
          <th>Domicilio</th>
          <th>Colonia</th>
          <th>Acciones</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of list; let i = index;">
          <td>{{object.name + ' ' + object.first_lastname + ' ' + object.second_lastname}}</td>
          <td>{{object.age}} años</td>
          <td>{{object.address}}</td>
          <td>
            {{object.colony}}
          </td>
          <td>
            <div class="table-actions">
              <button mat-icon-button class="accent" style="font-size: smaller;" matTooltip="Editar" routerLink="/admin/citizen/update/{{object.id}}">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="generateQR(object);" matTooltip="Descargar QR">
                <mat-icon>qr_code_2</mat-icon>
              </button>
              <button mat-icon-button class="accent" style="font-size: smaller;" matTooltip="Eliminar" (click)="presentAlert(object);">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
      <div class="col-6 ">
        <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
