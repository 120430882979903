
<div class="row justify-content-center">
  <div class="col-11">
    <h3>Nuevo Tipo de Cooperación</h3>
  </div>
</div>
<div class="row justify-content-center spablo-theme">
  <div class="col-11">
    <div class="row">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>

      <div class="form-row mb-2">
        <div class="form-group col-md-12">
          <label for="email" class="form-label contenido">Nombre*:</label>
          <input type="text" class="form-control form-control-sm" name="NumManzana" [ngClass] = "complexForm.controls['name'].invalid && complexForm.controls['name'].touched ? 'is-invalid' : complexForm.controls['name'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
          <div class="invalid-feedback" *ngIf="complexForm.controls['name'].hasError('required')  && complexForm.controls['name'].touched">Campo requerido</div>
        </div>
      </div>

    </div>
    <div class="row justify-content-end mt-3">
      <div class="col-8">
        <button mat-flat-button color="primary" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
