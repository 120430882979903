import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-admin-citizen',
  templateUrl: './admin-citizen.component.html',
  styleUrls: ['./admin-citizen.component.css']
})
export class AdminCitizenComponent implements OnInit {

  citizenMin = {
    manzanaNumber:0,
    status:'Ciudadano',
    filter:{
      value:'',
      name:''
    },
    max: 10,
    offset: 0,
  }

  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;

  metadata = {
    search: new Rx.Subject<string>(),
  }
  manzanaList = [];
  statusList = [
    'Ciudadano',
    'Autoridad',
    'Finado',
    'Jubilado',
    'Discapacitado'
  ]

  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
  ) {
      this.metadata.search.debounceTime(700)
      .switchMap(val => {
          console.log('called once',val);
          if(val ===''){
            this.list = [];
            this.citizenMin.filter.name = '';
          }else{
            this.citizenMin.filter.name="searchElement";
            this.list = [];
          }
          this.getcitizens();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
        console.log('error logged:');
        console.log(error);
      });
    }

    ngOnInit(): void {
      this.getcitizens();
      this.listByManzana();
    }

      /**función para obtener el listado de categorias
      */
      getcitizens(){
        this.loadding.show(true,"Espere un momento...");
        if(this.citizenMin.manzanaNumber == 0){
          this.session.postRequest("citizen:findAllByStatus",this.citizenMin).subscribe((data:any)=>{
            for(let  i = 0; i <data.object.instanceList.length; i++ ){
              data.object.instanceList[i].age = this.getAge(data.object.instanceList[i].date_of_birth);
            }
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:citizen:findAllByStatus",error);
            this.loadding.hide();
          })
        }else{
          this.session.postRequest("citizen:findAllByStatusAndManzanaNumber",this.citizenMin).subscribe((data:any)=>{
            for(let  i = 0; i <data.object.instanceList.length; i++ ){
              data.object.instanceList[i].age = this.getAge(data.object.instanceList[i].date_of_birth);
            }
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:citizen:findAllByStatusAndManzanaNumber",error);
            this.loadding.hide();
          })
        }


      }


      /**funcionalidad para descargar QR
      */
      generateQR(object){
        this.loadding.show(true,"Espere un momento..");
        this.session.postRequest("citizen:generateQR",object).subscribe((data:any)=>{
          this.loadding.hide();
          // console.log(data);
          let elem = document.createElement('a');
          elem.href = data.object.qr;
          elem.setAttribute('download',data.object.name.replace(/ /g, "_"));
          elem.setAttribute('target','_blank');
          document.body.appendChild(elem);
          elem.click();
        },error=>{
          this.loadding.hide();
        })
      }

      search(){
        if(this.citizenMin.filter.value != ''){
          this.metadata.search.next(this.citizenMin.filter.value);
        }else{
          this.getcitizens();
        }
      }

      getAge(age){
        let dB = moment(age);
        let today = moment();
        return today.diff(dB, 'years')
      }

      /**funcionalidad para obtener la manzanas
      */
      listByManzana(){
        this.session.postRequest("citizen:getAllByManzanaNumber",{}).subscribe((data:any)=>{
          this.manzanaList = data.object.instanceList.sort(function(a, b) {
            return a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
        },error=>{
          console.log(error);
        })
      }

      /**funcionalidad para cargar asistencias por manzana
      */
      presentAlert(object){
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
          width: '36rem',
          data: object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.deleteCitizen(object);
            }else{
              // El modal se cerro sin objeto

            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera

          }
        });
      }

      deleteCitizen(object){
        this.loadding.show(true,"Espere un momento...");
        this.session.postRequest("citizen:delete",object).subscribe((data:any)=>{
          for(let i = 0; i < this.list.length; i++){
            if(object.id == this.list[i].id){
              this.list.splice(i,1);
            }
          }
          this.loadding.hide();
        },error=>{
          console.log(error);
          this.loadding.hide();
        })
      }

      // funcion para obtener los datos del paginado.
      onPaginateChange(event){
        this.citizenMin.max = event.pageSize;
        this.citizenMin.offset = (event.pageIndex * event.pageSize);
        this.getcitizens();
      }

}

@Component({
  selector: 'app-admin-citizen-alert',
  templateUrl: 'app-admin-citizen-alert.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any) {}


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'event:001';
    }
    this.dialogRef.close(object);
  }

}
