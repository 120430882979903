import { Component,  ViewEncapsulation, OnInit } from '@angular/core';

@Component({
  selector: 'app-padron',
  templateUrl: './padron.component.html',
  styleUrls: ['./padron.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PadronComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }

}
