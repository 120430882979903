import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';

@Component({
  selector: 'app-admin-treasury-verify',
  templateUrl: './admin-treasury-verify.component.html',
  styleUrls: ['./admin-treasury-verify.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AdminTreasuryVerifyComponent implements OnInit {

  presenceList = [];

  presenceMin = {
    citizen:{
      id:''
    },
    isHere:false,
    max:100,
    offset:0
  }
  metadata = {
    presenceSelected:{
      id:''
    }
  }
  constructor(public session:SessionService ,
    public dialogRef: MatDialogRef<AdminTreasuryVerifyComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
    console.log(this.data);
    this.presenceMin.citizen.id = this.data.id;
    this.getInacistences()
  }
  /**función para obtener los
  */
  getInacistences(){
    this.session.postRequest("presence:findAllByCitizenAndIsHere",this.presenceMin).subscribe((data:any)=>{
      this.presenceList = data.object.instanceList;
    },error=>{
      console.log(error);
    })
  }


  /**funcionalidad para seleccionar una multa a aplicar
  */
  presenceSelected(object){
    this.metadata.presenceSelected = JSON.parse(JSON.stringify(object));
  }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        //recorremos presenceList para obtener todos los detalles del presenceList
        for(let item of this.presenceList){
          if(this.metadata.presenceSelected.id == item.id){
            this.metadata.presenceSelected = JSON.parse(JSON.stringify(item));
          }
        }
        object.transaction = 'ok';
        object.object = this.metadata.presenceSelected;
      }else{
        object.transaction = 'bad';
        object.code = 'presenceSelected:001';
      }
      this.dialogRef.close(object);
    }

}
