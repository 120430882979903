<app-admin-toolbar></app-admin-toolbar>

<div class="card fondo">

  <div class="container rounded">
    <div class="text-center m-2">
      <div class="row g-0">
        <div class="col-md-2">
          <img class="logo" src="/assets/images/primera-seccion.png" style="width: 6rem;" alt="padron">
        </div>
        <div class="col-md-8">
          <div class="card-body" style="padding: 33px;">
            <h1 class="MontseRegular">Padrón Ciudadano de</h1>
            <div class="color">
              <h1 class="MontseSemiBold">San Pablo Güilá (sección primera)</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-3 text-right">
        <button mat-button color="primary" (click)="back();">REGRESAR</button>
      </div>
    </div>
    <mat-tab-group mat-align-tabs="end" class="m-0" (selectedTabChange)="changeTab($event);">
      <mat-tab >
        <ng-template mat-tab-label>

          <img src="/assets/images/icono-datos-GRIS-05.svg" style="width: 2rem;" alt="">&nbsp;
          Información
        </ng-template>

        <div class="container rounded">
          <div class="row">
            <div class="col-md-2" style="padding-top: 40px;">
              <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
              <img src="/assets/images/foto.jpg" style="width: 100%;" alt="" *ngIf="metadata.img.fileName == ''">
              <img src="/assets/images/load.svg" style="width: 100%;" alt="" *ngIf="metadata.img.name != '' && !metadata.uploadted">
              <img src="{{metadata.img.file}}" style="width: 100%;" alt="" *ngIf="metadata.img.fileName != '' && metadata.img.name == ''">
              <button mat-button color="accent" style="width: 100%;"  (click)="loadImg();" *ngIf="metadata.img.name == ''">Cambiar</button>
              <button mat-flat-button color="accent" style="width: 100%;" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>

            </div>
            <div class="col rounded-end">

              <p class="text-center titulos">DATOS PERSONALES</p><hr>

              <form>

                <div class="form-row mb-2">
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">Nombre(s)*:</label>
                    <input type="text" class="form-control form-control-sm"  [ngClass] = "complexForm.controls['name'].invalid && complexForm.controls['name'].touched ? 'is-invalid' : complexForm.controls['name'].invalid ? '' : 'is-valid'" name="nombre" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['name'].hasError('required')  && complexForm.controls['name'].touched">Campo requerido</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">Apellido Paterno*:</label>
                    <input type="text" class="form-control form-control-sm" name="apellidoPaterno" [ngClass] = "complexForm.controls['first_lastname'].invalid && complexForm.controls['first_lastname'].touched ? 'is-invalid' : complexForm.controls['first_lastname'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['first_lastname']" [(ngModel)]="object.first_lastname" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['first_lastname'].hasError('required')  && complexForm.controls['first_lastname'].touched">Campo requerido</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">Apellido Materno*:</label>
                    <input type="text" class="form-control form-control-sm" name="apellidoMaterno" [ngClass] = "complexForm.controls['second_lastname'].invalid && complexForm.controls['second_lastname'].touched ? 'is-invalid' : complexForm.controls['second_lastname'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['second_lastname']" [(ngModel)]="object.second_lastname" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['second_lastname'].hasError('required')  && complexForm.controls['second_lastname'].touched">Campo requerido</div>
                  </div>
                </div>

                <div class="form-row mb-2">
                  <div class="form-group col-md-4">
                    <label for="email" class="form-label contenido">Número de Manzana*:</label>
                    <input class="form-control form-control-sm" name="NumManzana" [ngClass] = "complexForm.controls['manzana_number'].invalid && complexForm.controls['manzana_number'].touched ? 'is-invalid' : complexForm.controls['manzana_number'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['manzana_number']" [(ngModel)]="object.manzana_number" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['manzana_number'].hasError('required')  && complexForm.controls['manzana_number'].touched">Campo requerido</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">CURP*:</label>
                    <input type="text" class="form-control form-control-sm" name="curp" [ngClass] = "complexForm.controls['curp'].invalid && complexForm.controls['curp'].touched ? 'is-invalid' : complexForm.controls['curp'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['curp']" [(ngModel)]="object.curp">
                    <div class="invalid-feedback" *ngIf="complexForm.controls['curp'].hasError('pattern')  && complexForm.controls['curp'].touched">Revisa tu CURP, algo anda mal. </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">Fecha de Nacimiento*:</label>
                    <input type="date" class="form-control form-control-sm" name="fechaNacimiento" [ngClass] = "complexForm.controls['date_of_birth'].invalid && complexForm.controls['date_of_birth'].touched ? 'is-invalid' : complexForm.controls['date_of_birth'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['date_of_birth']" [(ngModel)]="metadata.dateBirth" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['date_of_birth'].hasError('required')  && complexForm.controls['date_of_birth'].touched">Campo requerido</div>
                  </div>
                </div>

                <p class="text-center" style="padding-top: 0px;">DATOS GENERALES</p>

                <div class="form-row mb-2">
                  <div class="form-group col-md-4">
                    <mat-form-field appearance="fill" style="width:100%;">
                      <mat-label>Etiqueta</mat-label>
                      <select matNativeControl required name="omStatus" [(ngModel)]="object.status">
                        <option value="{{object}}" *ngFor="let object of statusList">{{object == 'Ciudadano' ? 'Activo' : object}}</option>
                      </select>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="email" class="form-label contenido">Número de Integrantes:</label>
                    <input type="number" class="form-control form-control-sm" name="integrantes"  [(ngModel)]="object.members">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">Domicilio*:</label>
                    <input type="text" class="form-control form-control-sm" name="domicilio" [ngClass] = "complexForm.controls['address'].invalid && complexForm.controls['address'].touched ? 'is-invalid' : complexForm.controls['address'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['address']" [(ngModel)]="object.address" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['address'].hasError('required')  && complexForm.controls['address'].touched">Campo requerido</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="text" class="form-label contenido">Colonia*:</label>
                    <input type="text" class="form-control form-control-sm" name="colonia" [ngClass] = "complexForm.controls['colony'].invalid && complexForm.controls['colony'].touched ? 'is-invalid' : complexForm.controls['colony'].invalid ? '' : 'is-valid'" [formControl]="complexForm.controls['colony']" [(ngModel)]="object.colony" required>
                    <div class="invalid-feedback" *ngIf="complexForm.controls['colony'].hasError('required')  && complexForm.controls['colony'].touched">Campo requerido</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="email" class="form-label contenido">Teléfono:</label>
                    <input type="number" class="form-control form-control-sm" name="phone"  [(ngModel)]="object.phone">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="email" class="form-label contenido">Corre electronico:</label>
                    <input type="text" class="form-control form-control-sm" name="email"  [(ngModel)]="object.email">
                  </div>
                </div>
              </form>

              <div class="d-grid gap-2 col-6 mx-auto">
                <button class="btn btnColor" type="button" [disabled]="complexForm.invalid" (click)="send(object,true);">Registrar</button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab [disabled]="object.id == ''">

        <ng-template mat-tab-label naranja>

          <img src="/assets/images/icono-historial-GRIS-06.svg" style="width: 2rem;" alt=""> &nbsp;
          Historial

        </ng-template>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button style="padding: 2px;" class="accordion-button  contenido" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Servicios
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th scope="col" class="colorTable MontseSemiBold">Servicio</th>
                      <th scope="col" class="colorTable MontseSemiBold">Perido</th>
                      <!-- <th scope="col" class="colorTable MontseSemiBold">Asistio</th> -->
                      <th scope="col" class="colorTable MontseSemiBold">Jefe de Manzana</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let object of citizenServiceList; let i = index;">
                      <td><strong>#{{i+1}}</strong></td>
                      <td class="MonseConTeble">
                        <div class="form-group">
                          <input type="text" class="form-control form-control-sm" name="servicio{{i}}" [(ngModel)]="object.service">
                        </div>
                        <!-- <p>{{object.service}}</p> -->
                      </td>
                      <td class="MonseConTeble">
                        <div class="row">
                          <div class="form-group col-6">
                            <input type="number" class="form-control form-control-sm" name="fechaInicio{{i}}" [(ngModel)]="object.startDate">
                          </div>
                          <div class="form-group col-6">
                            <input type="number" class="form-control form-control-sm" name="fechaFinal{{i}}" [(ngModel)]="object.endDate">
                          </div>
                        </div>

                        <!-- <p>{{object.start_date | date:'yyyy'}} -  {{object.end_date | date:'yyyy'}}</p> -->
                      </td>
                      <!-- <td class="MonseConTeble">
                        <p *ngIf="object.entitle">Titular</p>
                        <p *ngIf="object.substitute">Suplente</p>
                      </td> -->
                      <td class="MonseConTeble">
                        <div class="form-group">
                          <input type="text" class="form-control form-control-sm" name="jefeManzana{{i}}" [(ngModel)]="object.manzana_boss">
                        </div>
                        <!-- <p>{{object.manzana_boss}}</p> -->
                      </td>
                      <td>
                        <button mat-icon-button (click)="saveService(object);" matTooltip="Guardar"> <mat-icon>save</mat-icon></button>
                        <button mat-icon-button (click)="deleteService(object);" matTooltip="Eliminar servicio"> <mat-icon>delete</mat-icon></button>

                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style="text-align: center; color: #6b6b6b; font-size: large;" *ngIf="citizenServiceList.length == 0">No hay servicios</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo" >
              <button  style="padding: 2px;" class="accordion-button collapsed  contenido" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Asambleas
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <!-- <table class="table">
                  <thead>
                    <tr class="colorTable MontseSemiBold">
                      <th scope="col">Concepto</th>
                      <th scope="col">Fecha <br> asist/inasist</th>
                      <th scope="col">Multa</th>
                      <th scope="col">Estatus</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="MonseConTeble">Ordinaria</td>
                    </tr>
                    <tr>
                      <td class="MonseConTeble">Ordinaria</td>
                    </tr>
                    <tr>
                      <td class="MonseConTeble">Ordinaria</td>
                    </tr>


                  </tbody>
                </table> -->
                <p style="text-align: center; color: #6b6b6b; font-size: large;" *ngIf="assembliesList.length == 0">No hay asistencias en asambleas</p>

              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button style="padding: 2px;" class="accordion-button collapsed contenido" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Cooperaciones
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <!-- <table class="table table-sm">
                  <thead>
                    <tr class="colorTable MontseSemiBold">
                      <th scope="col">Concepto</th>
                      <th scope="col">Estatus</th>
                      <th scope="col">Fecha de pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="MonseConTeble">
                      <td>Vigilancia</td>
                      <td>PAGADO</td>
                      <td>17/02/2021</td>
                    </tr>
                    <tr class="MonseConTeble">
                      <td>limpieza vial</td>
                      <td>PENDIENTE</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table> -->
                <p style="text-align: center; color: #6b6b6b; font-size: large;" *ngIf="benefactionList.length == 0">No hay cooperaciones aportadas.</p>

              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
