<div class="row justify-content-center">
  <div class="col-11">
    <p class="tTitle">Escanear tu código QR</p>
    <zxing-scanner
        [device]="deviceCurrent"
        (deviceChange)="onDeviceChange($event)"
        (camerasFound)="onCamerasFound($event)"
        (camerasNotFound)="camerasNotFound($event)"
        (scanSuccess)="onCodeResult($event)"
        (permissionResponse)="onHasPermission($event)"
        [formats]="allowedFormats"
    ></zxing-scanner>
  </div>
  <div class="col-11">
    <mat-form-field appearance="fill">
     <mat-select [value]="deviceSelected" (valueChange)="onDeviceSelectChange($event)">
       <mat-option value="">No hay dispositivo.</mat-option>
       <mat-option *ngFor="let device of availableDevices" [value]="device.deviceId">{{device.label}}</mat-option>
     </mat-select>
   </mat-form-field>
  </div>
</div>
