import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-event-add-presence',
  templateUrl: './admin-event-add-presence.component.html',
  styleUrls: ['./admin-event-add-presence.component.css']
})
export class AdminEventAddPresenceComponent implements OnInit {

  manzanaList = [];
  appleList = [{
    value:0,
    label:'Todos'
  }];
  metadata = {
    apple:''
  }

  constructor( private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminEventAddPresenceComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }


  ngOnInit(): void {
    //cargamos las asistencias por manzana
    // for(let item of this.manzanaList){
    //   let aux = {
    //     value:item,
    //     label:'Manzana '+item
    //   }
    //   this.appleList.push(aux);
    // }
    this.listByManzana();
  }

  /**funcionalidad para obtener el listado de manzanas
  */
  listByManzana(){
    this.session.postRequest("citizen:getAllByManzanaNumber",{}).subscribe((data:any)=>{
      let manzanaList = data.object.instanceList.sort(function(a, b) {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      this.appleList = manzanaList.map((item)=>{
        return {
          value:item,
          label:'Manzana '+item
        };
      });
      this.appleList.unshift({
        value:0,
        label:'Todos'
      })
      console.log(this.appleList);
    },error=>{
      console.log(error);
    })
  }

  send(){
    this.loadingService.show(true,"Espere un momento...");
    this.session.postRequest("event:close",{manzana_number:this.metadata.apple,id:this.data.id}).subscribe((data:any)=>{
      this.loadingService.hide();
      this.close(true);
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'event:001';
    }
    this.dialogRef.close(object);
  }

}
