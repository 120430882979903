<app-admin-toolbar></app-admin-toolbar>
  <div class="row justify-content-center spablo-theme" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Asambleas</h2>
        </div>
        <div class="col-3 text-right">
          <button mat-button color="primary" (click)="update('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva Asamblea</button>
        </div>
      </div>
      <div class="row justify-content-center">
        <mat-accordion class="col-12">
          <mat-expansion-panel *ngFor="let object of list; let i = index;"  (opened)="getAsistence(object);">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{object.name}}
                <span class="badge badge-warning" style="font-size: medium; margin: 5px;" *ngIf="object.status">Abierta</span>
                <span class="badge badge-info" style="font-size: medium; margin: 5px;" *ngIf="!object.status">Cerrada</span>

              </mat-panel-title>
              <mat-panel-description style="align-items: center; justify-content: flex-end;">
                <button mat-icon-button class="accent"  style="font-size: smaller;" matTooltip="Editar" (click)="update(object.id)" ><mat-icon>edit</mat-icon></button>
                <button mat-icon-button class="accent"  style="font-size: smaller;" matTooltip="Agregar más asistencias" (click)="loadAsistencesByMAnzana(object)" [disabled]="!object.status"><mat-icon>group_add</mat-icon></button>

                <button mat-icon-button class="accent"  style="font-size: smaller;" matTooltip="Eliminar" (click)="delete(object.id)" ><mat-icon>delete</mat-icon></button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p style="font-size: 13pt; font-weight: 600; color: black;">El total de asitentes a tu asamblea son: <span style="font-size: 13pt; font-weight: 600; color: #1f83c3;">{{object.totalAsistence}}</span>.</p>
            <span class="badge badge-success" style="font-size: medium; margin: 5px;" >Asistencia: {{object.totalAsistence}}</span>
            <span class="badge badge-danger" style="font-size: medium; margin: 5px;" >Faltas: {{object.totalINAsistence}}</span>

            <p>Fecha: {{object.date | date :'dd-MM-yyyy'}} {{object.hr}}:{{object.min}}</p>
            <br>
            <div class="row">
              <div class="col-3 mb-2" *ngFor="let object of object.manzanaList">
                <mat-card style="background:#f7f7f7;">
                  <p style="font-size: 13pt; font-weight: 600; color: black;"><strong>Manzana: {{object.manzana}}</strong></p>
                  <span class="badge badge-success" style="font-size: medium; margin: 5px;" >Asistencia: {{object.totalAsistence}}</span>
                  <span class="badge badge-danger" style="font-size: medium; margin: 5px;" >Faltas: {{object.totalInasistence}}</span>
                </mat-card>
              </div>

            </div>
            <br>
            <table class="table table-general">
              <thead class="header-table">
                <tr>
                  <th>Nombre</th>
                  <th>Asistencia</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let presence of object.presenceList; let i = index;">
                  <td>{{presence.citizen.name + ' ' + presence.citizen.first_lastname + ' ' + presence.citizen.second_lastname}}</td>
                  <td>
                    <div *ngIf="!object.status">
                      <button mat-icon-button class="accent"  style="font-size: smaller;" matTooltip="{{presence.citizen.name}} asistió" *ngIf="presence.isHere"><mat-icon style="color: #28a744;">task_alt</mat-icon></button>
                      <button mat-icon-button class="accent"  style="font-size: smaller;" matTooltip="{{presence.citizen.name}} no asistió" *ngIf="!presence.isHere"><mat-icon style="color: #dc3644;">highlight_off</mat-icon></button>
                    </div>
                    <div *ngIf="object.status">
                      <mat-radio-group aria-label="Select an option" name="precense{{i}}" [(ngModel)]="presence.isHere" (change)="updatePresence(presence)">
                        <mat-radio-button [value]="metadata.asistencia">Asistencia</mat-radio-button>
                        <mat-radio-button [value]="metadata.falta">Falta</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
              <div class="col-6 ">
                <mat-paginator [length]=object.paginator.total [pageSize]="10" [pageSizeOptions]="object.paginator.pageSizeOptions" (page)="onPaginateChangePresense($event,object)" style="background: #f4f4f5;">
                </mat-paginator>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
